// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

// bootstrap customizations 
// $primary: red;
// $body-bg: #282c34;

@import '~bootstrap/scss/bootstrap';

.headerImage{
  padding-top: 16px;
  width:100%
}

.lineDivider{
  height: 1px;
  width: 100%;
  background-color: #e3e3e3;
}